<template>
    <div :id="id" class="k-card-4">
        <div class="header">Nombre de sessions</div>

        <div class="k-flex-row flex-nowrap">
            <span class="k-stats-total">{{ sessionsCount }}</span>
            <small>
                Une session correspond à une série d'interactions séparées par
                un court laps de temps.
            </small>
        </div>
        <div v-if="sessionsCount > 0">
            <ChartBarTime :canvas-id="canvasId" :data="sessionsData" />
        </div>
    </div>
</template>

<script>
import ChartBarTime from "@/components/model/stats/chart/ChartBarTime";
import { debounce } from "lodash";

export default {
    components: {
        ChartBarTime,
    },

    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
    },

    data: function() {
        return {
            canvasId: this.id + "-canvas",
            sessionsCount: "?",
            sessionsData: {},
        };
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                this.sessionsCount = await this.$store.dispatch(
                    "stats/getSessionsTotal",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.sessionsData = await this.$store.dispatch(
                    "stats/getSessionsPerDay",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.$emit("load", this.sessionsData);
            }
        }, 100),
    },
};
</script>
