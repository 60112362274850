<template>
    <div>
        <canvas :id="canvasId" />
    </div>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { fr } from "date-fns/locale";

export default {
    props: {
        canvasId: { type: String, required: true },
        data: { type: Object },
        title: { type: String },
    },

    data: function() {
        return {
            chart: null,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: !!this.title,
                        text: this.title,
                    },
                },
                scales: {
                    xAxes: {
                        display: true,
                        type: "time",
                        time: {
                            tooltipFormat: "dd MMMM yyyy",
                            unit: "day",
                            displayFormats: {
                                day: "dd MMM",
                            },
                        },
                        ticks: {
                            source: "data",
                        },
                        adapters: {
                            date: {
                                locale: fr,
                            },
                        },
                    },
                    yAxes: {
                        display: true,
                    },
                },
            },
        };
    },

    watch: {
        data() {
            this.updateChartData();
        },
    },

    methods: {
        updateChartData() {
            this.chart.data = {
                labels: Object.keys(this.data).map(k => new Date(k)),
                datasets: [
                    {
                        backgroundColor: "#00817b",
                        data: Object.values(this.data),
                    },
                ],
            };
            this.chart.update();
        },
    },

    mounted() {
        this.chart = new Chart(document.getElementById(this.canvasId), {
            type: "bar",
            options: this.chartOptions,
            data: {},
        });
    },
};
</script>
