<template>
    <div :id="id" class="k-card-4">
        <div class="header">Fiches les plus consultées</div>

        <div class="k-flex-col" v-if="topFiches.length">
            <div class="top-fiche-row" v-for="(fiche, i) in topFiches" :key="i">
                <div>
                    <span class="award" :style="`background:${colors[i]}`">{{
                        i + 1
                    }}</span>
                </div>
                <div class="text-wrap">{{ fiche.name }}</div>
                <div class="text-kalkin-1">
                    <b>{{ fiche.touch }}</b> <b-icon-eye-fill />
                </div>
            </div>
        </div>
        <div class="text-center mt-3" v-else>
            <i>Aucune information disponible</i>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";

export default {
    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
        count: { type: Number, default: 5 },
    },

    data: function() {
        return {
            canvasId: this.id + "-canvas",
            topFiches: [],
            colors: ["#AF950D", "#CCCCCC", "#AD8A56"],
        };
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                const fiches = await this.$store.dispatch(
                    "stats/getTouchesOnFiches",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.$emit("load", fiches);
                this.topFiches = fiches.slice(0, this.count);
            }
        }, 100),
    },
};
</script>

<style lang="scss" scoped>
.top-fiche-row {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
    gap: 5px;

    & > div:nth-child(1) {
        min-width: 25px;
    }
    & > div:nth-child(2) {
        flex: 1;

        padding: 4px 8px;
        background: lighten($secondary-color, 40);
        border-radius: 6px;
        font-variant: small-caps;
    }
    background: lighten($secondary-color, 46);
    border-radius: 8px;
}

.award {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;
    border-radius: 100%;

    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    background: $primary-color;
    color: white;
    border: 3px double #00000044;
}
</style>
