<template>
    <div :id="id" class="k-card-4">
        <div class="header">Interactions</div>

        <div class="k-flex-row flex-nowrap">
            <span class="k-stats-total">{{ touchesCount }}</span>
            <small>
                Correspond au nombre d'interactions avec les écrans effectuées
                par l'ensemble des utilisateurs, sur toute la période.
            </small>
        </div>
        <div v-if="touchesCount > 0">
            <ChartBarTime :canvas-id="canvasId" :data="touchesData" />
        </div>
    </div>
</template>

<script>
import ChartBarTime from "@/components/model/stats/chart/ChartBarTime";
import { debounce } from "lodash";

export default {
    components: {
        ChartBarTime,
    },

    props: {
        id: { type: String, required: true },
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
    },

    data: function() {
        return {
            canvasId: this.id + "-canvas",
            touchesCount: "?",
            touchesData: {},
        };
    },

    watch: {
        $props: {
            async handler() {
                await this.fetchData();
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        fetchData: debounce(async function() {
            if (this.installations.length > 0) {
                this.touchesCount = await this.$store.dispatch(
                    "stats/getTouchesTotal",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.touchesData = await this.$store.dispatch(
                    "stats/getTouchesPerDay",
                    {
                        projectId: this.projectId,
                        installations: this.installations.map(i => i.id),
                        start: this.from,
                        end: this.to,
                    },
                );
                this.$emit("load", this.touchesData);
            }
        }, 100),
    },
};
</script>
